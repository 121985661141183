import React from "react"

import { useRoutes } from "react-router-dom"
import MainView from "../views/MainView";
import RedirectView from "../views/RedirectView";

export default function DashboardRoutes() {
    return useRoutes([
        {
            path: "/redirectTo/*",
            children: [
                {
                    path: "*",
                    element: <RedirectView />,
                }
            ]
        },
        {
            path: "/*",
            children: [
                {
                    path: "*",
                    element: <MainView />,
                },
            ],
        },
    ])
}