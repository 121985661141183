import styles from './ButtonsWrapper.module.css';

const Button = ({children}: {children: JSX.Element | JSX.Element[]}) => {
    return (
        <div className={styles.buttonsWrapper}>{children}</div>
    )

}

export default Button
