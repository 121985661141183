import {useSearchParams} from "react-router-dom";

const RedirectView = () => {
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get("url")

    if(redirectUrl) {
        window.location.href = redirectUrl;
    }

    return (
        <div>Brak parametru url w get</div>
    )
}
export default RedirectView;